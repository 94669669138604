export const checkConditionValues = {
  methods: {
    hasValue(condition, value) {
      let hasValue = false
      switch (condition.operator) {
        case '==':
          if (condition.value == value || (Array.isArray(value) && value.length === 1 && value.includes(condition.value))) {
            hasValue = true
          }
          break;
        case '!=':
          if (condition.value !== value || (Array.isArray(value) && value.length === 1 && !value.includes(condition.value))) {
            hasValue = true
          }
          break;
        case '==contains':
          if (Array.isArray(value)) {
            const found = value.some(r => condition.value.includes(r))

            if (found) {
              hasValue = true
            }
          } else {
            if (value.includes(condition.value)) {
              hasValue = true
            }
          }
          break;
        case '!=contains':
          if (!value.includes(condition.value)) {
            hasValue = true
          }
          break;
        case '==empty':
          if (!value) {
            hasValue = true
          }
          break;
        case '!=empty':
          if (value) {
            hasValue = true
          }
          break;
        case '<':
          if (+condition.value < +value) {
            hasValue = true
          }
          break;
        case '>':
          if (+condition.value > +value) {
            hasValue = true
          }
          break;
      }

      return hasValue
    },
    designateValue(type, def) {
      if (type === 'group_selection' && def !== 'not_accepted') {
        return def
      }

      if (def && def !== 'not_accepted') {
        if (type === 'checkbox' || type === 'multi_select') return def
        if (type === 'file_upload') return []

        return def
      }

      if (type === 'checkbox' || type === 'multi_select') return []
      if (type === 'dropdown') return null
      if (type === 'file_upload') return []

      return null
    },
    designateOptions(type, options) {
      if (type === 'dropdown') {
        return options.reduce((arr, item) => {
          arr.push({ value: item, text: item })
          return arr
        }, [{ value: null, text: 'Select option' }])
      }

      return options
    },
    transformField(type) {
      switch (type) {
        case 'short_text':
          return 'text'
        case 'phone':
          return 'tel'
        default:
          return type
      }
    },
    checkHiddenAttr(obj) {
      return obj.length > 0
    },
  },
}

export const _ = null

import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function useGroupUsersList() {
  // Use toast
  const toast = useToast()

  const refGroupUsersTable = ref(null)

  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const groupId = computed(() => store.getters['applicationRegistration/getSelectedGroupForViewingUsers'])

  const dataMeta = computed(() => {
    const localItemsCount = refGroupUsersTable.value ? refGroupUsersTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refGroupUsersTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchGroupUsersList = (ctx, callback) => {
    const payloadData = {
      groupId: groupId.value,
      perPage: perPage.value,
      page: currentPage.value,
    }

    store
      .dispatch('applicationRegistration/fetchGroupUsersList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total } = response.data.meta

        callback(data)
        totalItems.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching data',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }


  return {
    fetchGroupUsersList,
    perPage,
    currentPage,
    dataMeta,
    totalItems,
    refGroupUsersTable,

    refetchData,
  }
}

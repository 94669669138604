<template>
  <b-modal
    id="group-users-list"
    size="lg"
    centered
    title="Group Members"
    body-class="p-0"
    hide-footer
  >
    <b-table
      ref="refGroupUsersTable"
      :items="fetchGroupUsersList"
      thead-class="table-header"
      show-empty
      :busy="isTableBusy"
      empty-text="No matching records found"
      class="position-relative"
      responsive
    >
      <!-- Loader -->
      <template #table-busy>
        <div class="d-flex justify-content-center">
          <b-spinner 
            class="align-middle"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          class="d-flex align-items-center justify-content-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            pills
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          />

        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { 
  BTable, BRow, BCol,
  BPagination, BSpinner,
} from 'bootstrap-vue'

import useGroupUsersList from '@/components/site/group-users-list/useGroupUsersList'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BPagination,
    BSpinner,
  },
  data() {
    return {
      isTableBusy: false,
    }
  },
  setup() {
    const {
      fetchGroupUsersList,
      perPage,
      currentPage,
      dataMeta,
      totalItems,
      refGroupUsersTable,

      refetchData,
    } = useGroupUsersList()

    return {
      fetchGroupUsersList,
      perPage,
      currentPage,
      dataMeta,
      totalItems,
      refGroupUsersTable,

      refetchData,
    }
  },
}
</script>

<template>
  <div class="credit__card">

    <p class="credit__card-text">
        <template v-if="applicationFee">A non refundable application fee of ${{applicationFee}} will be charged to your card. </template>
<!--        <template v-else>Your card will not be charged; however, a temporary hold will be placed on your card.</template> -->
        <!-- The remaining Tuition fee will be charged on application approval. -->
    </p>

    <div
      v-if="!hideCC"
      class="credit-card"
    >
      <h2 class="credit-card__title">Name on Card</h2>
      <div class="credit-card__row">
        <input
          v-model="cardData.name"
          type="text"
          name="name"
          id="name"
          placeholder="Name on Card"
          :class="{'is-invalid': $v.cardData.name.$dirty && !$v.cardData.name.required }"
        />
      </div>
      <h2 class="credit-card__title">Credit Card</h2>
      <div class="credit-card__row credit-card__row-two-col ">
        <div class="credit-card__number-row">
          <input
            class="credit-card__number"
            v-model="cardData.number"
            type="text"
            autocomplete="cc-number"
            placeholder="Card Number"
            v-mask="['#### #### #### ####','#### #### #### ###']"
            :masked="true"
            :class="{'is-invalid': $v.cardData.number.$dirty && (!$v.cardData.number.required || !$v.cardData.number.number)}"
          />
        </div>
        <div class="credit-card__row-right-col">

          <input
            v-model="cardData.expMonth"
            class="credit-card__month"
            type="text"
            maxlength="2"
            placeholder="MM"
            autocomplete="cc-exp-month"
            :class="{'is-invalid': $v.cardData.expMonth.$dirty &&
                        (!$v.cardData.expMonth.required || !$v.cardData.expMonth.between || !$v.cardData.expMonth.numeric) }"
          />
          <span>/</span>
          <input
            v-model="cardData.expYear"
            class="credit-card__year"
            type="text"
            maxlength="2"
            placeholder="YY"
            autocomplete="cc-exp-year"
            :class="{'is-invalid': $v.cardData.expYear.$dirty &&
                        (!$v.cardData.expYear.required || !$v.cardData.expYear.minValue || !$v.cardData.expYear.numeric)}"
          />
          <input
            v-model="cardData.cvc"
            class="credit-card__cvc"
            type="text"
            placeholder="CVC"
            v-mask="['###', '####']"
            :class="{'is-invalid': $v.cardData.cvc.$dirty &&
                    (!$v.cardData.cvc.required || !$v.cardData.cvc.minLength || !$v.cardData.cvc.maxLength || !$v.cardData.cvc.numeric || !$v.cardData.cvc.cvc) }"
          />
        </div>

      </div>
      <div v-if="$v.$invalid" class="form__error credit-card__error">
        <div v-if="$v.cardData.name.$dirty && !$v.cardData.name.required">
          The Name is required.
        </div>
        <div v-if="$v.cardData.number.$dirty && (!$v.cardData.number.required || !$v.cardData.number.number)">
          The card number is invalid.
        </div>
        <div v-if="$v.cardData.expMonth.$dirty && (!$v.cardData.expMonth.required || !$v.cardData.expMonth.between || !$v.cardData.expMonth.numeric)">
          The month is invalid.
        </div>
        <div v-if="$v.cardData.expYear.$dirty && (!$v.cardData.expYear.required || !$v.cardData.expYear.minValue || !$v.cardData.expYear.numeric)">
          The year is invalid.
        </div>
        <div v-if="$v.cardData.cvc.$dirty && (!$v.cardData.cvc.required || !$v.cardData.cvc.minLength || !$v.cardData.cvc.maxLength || !$v.cardData.cvc.numeric || !$v.cardData.cvc.cvc)">
          The CVC code is invalid.
        </div>
      </div>
    </div>

    <!-- <div class="card__fee"><span>Tuition Fee:</span> <span>$ {{totalSum || 0}}</span></div> -->
    <div class="payment__plan" v-if="false">
        <div class="form__row">
            <label class="center nice-checkbox">
                <input
                  v-model="paymentPlanActivated"
                  @change="$emit('activetePaymentPlan', paymentPlanActivated)"
                  type="checkbox" />
                <span>I Would like to make the Payment in monthly installments</span>
            </label>
        </div>
        <div class="row" v-if="paymentPlanActivated">
            <div class="col-6">
                <v-select
                    class="select"
                    placeholder="Amount of Payments"
                    :options="paymentMonthCountOptions"
                    label="amount"
                    :searchable="false"
                    v-model="paymentMonthCount"
                    @input="$emit('updateMonthCount', paymentMonthCount)"
                />
            </div>
            <div class="col-6">
                <h4 class="payment__plan-monthly">Monthly payment: ${{monthlyPayment}}</h4>
            </div>
      </div>
    </div>

    <label
      class="center nice-checkbox"
      :class="{'is-invalid': $v.terms.$dirty && !$v.terms.sameAs }"
    >
      <input
        v-model="terms"
        @change="$v.terms.$touch()"
        type="checkbox"
      />
      <span>I agree to terms & conditions</span>
    </label>

    <p class="form__error card__error">{{ errorTCMessage }}</p>

    <div class="credit-card__buttons">
      <span
        class="btn btn--color-outline-inactive credit-card__button"
        @click="$store.commit('applicationRegistration/DECREMENT_TAB_INDEX')"
      >Previous</span>
    <button
        class="btn btn--color-dark credit-card__button"
        @click="payout"
        :disabled="isBtnDisabled"
        :class="{ 'btn--loading': isLoading }"
    ><span v-if="!isLoading" v-html="submitButtonText"></span></button>
    </div>

  </div>

</template>

<script>
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css'

  import { required, minLength, maxLength, minValue, between, numeric, sameAs } from 'vuelidate/lib/validators'
  import {isSecurityCodeValid, isValid} from "creditcard.js";

  export default {
    components: {
      vSelect,
    },
    props: {
      applicationFee: {
        type: Number,
        default: 0,
      },
      totalSum: {
        type: Number,
        default: 0,
      },
      paymentPlan: {
        type: Object,
        default: () => {}
      },
      btnText: {
        type: String,
        default: '',
      },
      isBtnDisabled: {
        type: Boolean,
        default: false,
      },
      hideCC: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        cardData: {
          name: null,
          number: '',
          expMonth: null,
          expYear: null,
          cvc: null
        },
        paymentMonthCount: null,
        paymentPlanActivated: false,
        isLoading: false,
        errorTCMessage: '',
        terms: false,
      };
    },
    validations: {
      cardData: {
        name: { required },
        number: {
          required,
          number: function(){ return isValid( this.cardData.number ) }
        },
        expMonth: {
          required,
          between: between(1, 12),
          numeric
        },
        expYear: {
          required,
          minValue: minValue(23),
          numeric
        },
        cvc: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(4),
          numeric,
          cvc: function(){ return isSecurityCodeValid( this.cardData.number, this.cardData.cvc ) }
        }
      },
      terms: {
        sameAs: sameAs( () => true )
      },
    },
    computed: {
      submitButtonText() {
        if (this.btnText) {
          return this.btnText
        }
        return 'Submit form, <br>continue to documents'
      },
      isActivePaymentPlan() {
        return this.payment_plan?.is_enable
      },
      paymentMonthCountOptions() {
        const maxMonths = this.paymentPlan.max_months
        if(maxMonths){
          return Array.from({length: maxMonths}, (_, index) => index + 1)
        }
        return []
      },
      monthlyPayment() {
        if(this.paymentMonthCount){
          return (+this.totalSum/+this.paymentMonthCount).toFixed(2)
        }
        return +this.totalSum.toFixed(2);
      }
    },
    methods: {
      async payout() {
        this.errorTCMessage = ''

        if (this.$v.$invalid){
          await this.$v.$touch();

          if (this.$v.terms.$invalid){
            this.errorTCMessage = 'Please, agree to out terms and conditions'
            return
          }

          if (!this.hideCC && this.$v.cardData.$invalid) {
            document.querySelector('.is-invalid').focus()
            return
          }
        }
        // this.isLoading = true
        this.$emit('authCreditCard', this.cardData)
      }
    }
  };
</script>

<style lang="scss">
.payment__plan{
    margin-bottom: 30px;
    &-monthly{
        font-size: 20px;
        line-height: 26px;
        text-align: right;
        letter-spacing: -0.01em;
    }
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.is-invalid{
    color: #f00;
}
.col-6 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 50%;
    max-width: 50%;
}
</style>
